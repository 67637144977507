import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useSelector } from 'react-redux';
import { useStyles } from './style';
import { CircularProgress, Button } from '@material-ui/core';

const Itinerary = ({ cbSetTitle }) => {
  const daysPlanning = useSelector(store => store.dataToGetItinerary);
  const destiny = useSelector(store => store.form.destiny)
  const [itinerary, setItinerary] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    cbSetTitle('Seu PDF está sendo prepado.');
    window.scrollTo({ top: 0 });

    if (daysPlanning.length !== 0) {
      api.post('/makeItinerary', { destiny, daysPlanning })
        .then(response => {
          setItinerary(response.data);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itinerary !== null) {
      cbSetTitle('Tudo pronto!');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itinerary]);

  function openPdfFile() {
    document.getElementById('open-button').addEventListener('click', function(){
      var w = window.open('about:blank');
  
      setTimeout(function(){ //FireFox seems to require a setTimeout for this to work.
          const iframe = w.document.createElement('iframe');

          iframe.style = 'width: 100%; height: 100vh;'
          w.document.body.style = 'padding: 0; margin: 0; box-sizing: border-box;'

          w.document.body.appendChild(iframe).src = `data:application/pdf;base64,${itinerary}`;
      }, 0);
  });
  }

  return (
    <div className={classes.paper}>
      {itinerary === null && (
        <CircularProgress />
      )}
      {itinerary !== null && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            id="open-button" 
            target="_blank"
            style={{ textTransform: 'initial', margin: 5 }}
            onClick={openPdfFile}
            disableElevation
          > Abrir arquivo </Button>
          
          <Button
            href="/"
            color="primary"
            style={{ textTransform: 'initial', margin: 5 }}
            disableElevation
          > Voltar para a página inicial </Button>
        </div>
      )}
      
    </div>
  )
}

export default Itinerary;