const INITIAL_STATE = [];

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case 'ADD_DATA_TO_GET_ITINERARY':
      return action.dataToGetItinerary;

    default:
      return state;
  }
}

export default reducer;