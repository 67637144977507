const { makeStyles, createStyles } = require("@material-ui/core");

const useStyle = makeStyles((theme) => createStyles({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },

  card: {
    borderWidth: 1,
    borderColor: theme.palette.grey[700],
    borderStyle: 'solid',
    width: 210,
    height: 159,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    margin: 5,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: 160,
      margin: 3,
      height: 149,
    }
  },

  cardSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main.replace('rgb', 'rgba').replace(')', ', 0.1)'),
  },

  cardImage: {
    width: 66,
  },

  cardTitle: {
    marginTop: 10,
    fontSize: 23,
  }
}));

export default useStyle;