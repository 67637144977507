import React, { useEffect, useState } from 'react';
import useStyle from './style';
import FormSchedule from '../FormSchedule';
import { Grow, Box, Card, CardContent, Typography, Button } from '@material-ui/core';
import { Close, Add } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getStringDate } from '../../utils/DateUtils';
import { removeSchedule } from '../../store/form/actions';
import ButtonNext from '../../components/ButtonNext';
import { getPointsToSelectNow } from '../../store/status/actions';

const Schedules = ({ cbSetTitle, cbNextPage }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const schedules = useSelector(store => store.form.schedules);
  const [showFormSchedule, setShowFormSchedule] = useState(false);

  useEffect(() => {
    cbSetTitle('Quais seus horários disponíveis?');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(schedules);
  }, [schedules]);

  function goToNextPage() {
    if (schedules.length !== 0) {
      cbNextPage();
      dispatch(getPointsToSelectNow(true));
    }
    else {
      alert('Por favor, informe seus horários disponíveis');
    }
  }

  return (
    <Box>
      <ButtonNext onClick={goToNextPage} />
      <Grow in={true}>
        <Box component="div">
          <FormSchedule show={showFormSchedule} setVisible={setShowFormSchedule} />
          <Box component="section" className={classes.content}>
            {schedules.map((schedule, scheduleIndex) => (
              <Card variant="outlined" className={classes.card} key={scheduleIndex}>
                <CardContent>
                  <Box className={classes.topBarCard}>
                    <Box className={classes.title}>
                      <b> {getStringDate(schedule.date)} </b>
                    </Box>
                    <Box
                      className={classes.exit}
                      onClick={() => dispatch(removeSchedule(scheduleIndex))}
                    >
                      <Close className={classes.closeIcon} />
                    </Box>
                  </Box>
                  <Box className={classes.times}>
                    {schedule.times.map((time, timeIndex) => (
                      <Typography variant="h5" key={timeIndex} className={classes.time}>{time}</Typography>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            ))}

            <Button
              className={classes.cardAdd}
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => setShowFormSchedule(true)}
            >
              <Add className={classes.addIcon} />
            </Button>
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}

export default Schedules;