const { makeStyles, createStyles } = require("@material-ui/core");

const useStyle = makeStyles(theme => createStyles({
  absolute: {
    display: 'none',
    position: 'fixed',
    width: '100%',
    height: '100vh',
    zIndex: 20000,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    justifyContent: 'center',
    alignItems: 'center',
  },

  show: {
    display: 'flex',
    top: 0,
    left: 0,
  },

  hidden: {
    display: 'none',
  },

  boxWindow: {
    width: 700,
    padding: 30,
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      margin: 10,
    }
  },

  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },

  closeButton: {
    cursor: 'pointer',
  },

  description: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
  },

  link: {
    color: theme.palette.primary.main,
    fontSize: 15,
    marginTop: 5,
  },

  categories: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },

  category: {
    fontSize: 15,
    marginRight: 5,
    marginBottom: 5,
    padding: 5,
    width: 'min-content',
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main.replace('rgb', 'rgba').replace(')', ', 0.2)'),
  },

  locals: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  local: {
    marginTop: 10,
    textAlign: 'center',
  }
}));

export default useStyle;