import { makeStyles, createStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => {
  return createStyles({
    container: {
    },

    message: {
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 25,
      color: theme.palette.primary.main,
    },

    boxDay: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 30,
      backgroundColor: theme.palette.grey[100],
      borderRadius: 5,
      paddingTop: 20,
      paddingBottom: 20,
    },

    boxDayTitle: {
      fontSize: 20,
      fontWeight: 'bold',
    },

    cards: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },

    card: {
      width: 250,
      margin: 10,
      borderColor: 'rgba(0, 0, 0, 0)',
      borderWidth: 1,
      borderStyle: 'solid',
      boxShadow: 0,
    },

    cardSelected: {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main.replace('rgb', 'rgba').replace(')', ', 0.1)'),
    },

    cardImage: {
      width: 250,
      height: 140.625,
    },

    cardContent: {
      padding: 15,
    },

    cardTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    cardName: {
      marginRight: 5,
    },

    cardTimes: {
      '& *': {
        fontSize: 12,
      }
    },

    cardCategories: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 10,
      marginBottom: 10,
    },

    cardCategory: {
      fontSize: 12,
      marginRight: 5,
      marginBottom: 5,
      padding: 5,
      width: 'min-content',
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main.replace('rgb', 'rgba').replace(')', ', 0.2)'),
    },

    cardButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },

    titleTime: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: `${theme.palette.text.primary.substring(0, 12)}, 0.6)`,
      textAlign: 'center',
      marginTop: 17,
      marginBottom: 10,
      fontSize: 20,
      fontWeight: 'bold',
      '& *': {
        marginLeft: 5,
        marginRight: 5
      }
    },

    errorMessage: {
      margin: 5,
      padding: 10,
      borderRadius: 5,
      fontWeight: 'bold',
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main,
    }
  })
});

export default useStyle;