import { makeStyles, createStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => createStyles({
  
  selectField: {
    width: 450,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },

  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default useStyle;