import React from 'react';
import useStyle from './style';
import { Button } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

const ButtonNext = ({ onClick }) => {
  const classes = useStyle();

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.floatButton}
      size="large"
      onClick={onClick}
    ><ArrowForward /></Button>
  );
}

export default ButtonNext;