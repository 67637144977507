import React from 'react';
import Routes from './Routes';
import { ThemeProvider, createMuiTheme, Paper } from '@material-ui/core';

function App({ store }) {
  const lightTheme = createMuiTheme({
    palette: {
      type: "light",
      primary: {
        main: 'rgb(231, 99, 39)',
        contrastText: '#ffffff',
      },
      background: {
        default: '#FAFAFA',
        paper: '#FAFAFA',
      }
    },
    typography: {
      h1: { 
        fontSize: 32,
      },
      h2: {
        fontSize: 25,
      },
      h3: {
        fontSize: 22,
        fontWeight: 'lighter'
      },
      h4: {
        fontSize: 15,
      },
      h5: {
        fontSize: 22,
        fontWeight: 'lighter'
      }
    }
  });
  
  return (
    <ThemeProvider theme={lightTheme}>
      <Paper style={{ minHeight: '100vh' }} component="div">
        <Routes/>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
