import { combineReducers, createStore } from 'redux';
import formReducer from './form/reducers';
import statusReducer from './status/reducers';
import dataToGetItineraryReducer from './dataToGetItinerary/reducers';

export const rootReducer = combineReducers({
  form: formReducer,
  status: statusReducer,
  dataToGetItinerary: dataToGetItineraryReducer
});

export const store = createStore(rootReducer);
