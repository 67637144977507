const { makeStyles, createStyles } = require("@material-ui/core");

const useStyle = makeStyles((theme) => createStyles({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  exit: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  closeIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: 10,
  },

  card: {
    width: 250,
    margin: 10,
    height: 300
  },

  cardAdd: {
    width: 250,
    height: 300,
    margin: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  
  addIcon: {
    fontSize: 50,
    color: '#FFFFFF',
  },

  topBarCard: {
    display: 'flex',
    marginBottom: 20,
  },

  times: {
    maxHeight: 200,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflowY: 'auto',
  },

  time: {
    fontSize: 16,
    height: 20,
    marginTop: 5,
    marginBottom: 5,
    fontWeight: 'lighter',
    display: 'inline',
    padding: 10,
    backgroundColor: theme.palette.primary.main.replace('rgb', 'rgba').replace(')', ', 0.1)'),
    borderRadius: 5,
    marginRight: 5,
  },

  floatButton: {
    position: 'fixed',
    bottom: 20,
    right: 20,
  }
}));

export default useStyle;