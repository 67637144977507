import axios from 'axios';

export let baseURL = 'https://carpediemhomes.xyz';
// export let baseURL = 'http://localhost:8000';

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://carpediemhomes.xyz';
  // baseURL = 'http://localhost:8000';
}

const api = axios.create({ baseURL });

export default api;
