const { makeStyles, createStyles } = require("@material-ui/core");

const useStyle = makeStyles(theme => createStyles({
  absolute: {
    display: 'none',
    position: 'fixed',
    width: '100%',
    height: '100vh',
    zIndex: 20000,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    justifyContent: 'center',
    alignItems: 'center',
  },

  show: {
    display: 'flex',
    top: 0,
    left: 0,
  },

  hidden: {
    display: 'none',
  },

  boxWindow: {
    maxWidth: 700,
    padding: 30,
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      // margin: 100,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 250,
    }
  },

  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },

  closeButton: {
    cursor: 'pointer',
  },

  dateBox: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& h3': {
      marginTop: 10,
    }
  },

  dateField: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  dateText: {
    display: 'inline',
    marginLeft: 20,
  },

  timesBox: {
    marginTop: 40,
    marginBottom: 20,
  },

  times: {
    marginTop: 20,
    marginBottom: 10,
    maxHeight: 500,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 200,
      flexDirection: "column",
    },
  },

  colTime: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    '& h4': {
      marginTop: 10,
      marginBottom: 10,
    }
  },
  
  bottomButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}));

export default useStyle;