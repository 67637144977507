import { makeStyles, createStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => createStyles({
  image: {
    borderRadius: 10,
    width: 330,
    height: 220.625,
  },

  carousel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default useStyle;