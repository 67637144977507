import { serializeDate } from "../../utils/DateUtils";

const INITIAL_STATE = {
  destiny: {
    uf: '',
    local: '',
  },
  categories: [],
  schedules: [
  ],
};

const reducer = (store = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_DESTINY':
      return { ...store, destiny: action.destiny };


    case 'ADD_SCHEDULE':

      const schedule = action.schedule;

      schedule.times.sort((a, b) => {
        return Number.parseInt(a.substring(0, 2)) - Number.parseInt(b.substring(0, 2));
      }); 

      const newSchedules = [...store.schedules, schedule];

      newSchedules.sort((a, b) => {
        const serializatedA = serializeDate(a.date);
        const serializatedB = serializeDate(b.date);

        return new Date(serializatedA.year, serializatedA.month, serializatedA.day) - new Date(serializatedB.year, serializatedB.month, serializatedB.day);
      });

      return {
        ...store,
        schedules: newSchedules
      }


    case 'REMOVE_SCHEDULE':
      const schedules = store.schedules.filter((schedule, index) => {
        return index !== action.scheduleIndex;
      })

      return { ...store, schedules };



    case 'ADD_CATEGORY':
      return {
        ...store,
        categories: [...store.categories, action.category]
      }



    case 'REMOVE_CATEGORY':
      return {
        ...store,
        categories: store.categories.filter((category) => category !== action.category)
      }



    default:
      return store;
  }
}

export default reducer;