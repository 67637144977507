import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => createStyles({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}));
