import React, { useState } from 'react';
import useStyle from './style';
import { Box, Typography, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Close, Done } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { addSchedule } from '../../store/form/actions';
import { getCurrentDate, getStringDate } from '../../utils/DateUtils';

const FormSchedule = ({ show, setVisible }) => {
  const INITIAL_TIMES = [
    { time: '00:00', selected: false },
    { time: '01:00', selected: false },
    { time: '02:00', selected: false },
    { time: '03:00', selected: false },
    { time: '04:00', selected: false },
    { time: '05:00', selected: false },
    { time: '06:00', selected: false },
    { time: '07:00', selected: false },
    { time: '08:00', selected: false },
    { time: '09:00', selected: false },
    { time: '10:00', selected: false },
    { time: '11:00', selected: false },
    { time: '12:00', selected: false },
    { time: '13:00', selected: false },
    { time: '14:00', selected: false },
    { time: '15:00', selected: false },
    { time: '16:00', selected: false },
    { time: '17:00', selected: false },
    { time: '18:00', selected: false },
    { time: '19:00', selected: false },
    { time: '20:00', selected: false },
    { time: '21:00', selected: false },
    { time: '22:00', selected: false },
    { time: '23:00', selected: false },
  ];
  const classes = useStyle();
  const dispatch = useDispatch();
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [date, setDate] = useState(getCurrentDate());
  const [times, setTimes] = useState([...INITIAL_TIMES]);

  function handleChangeTime(index) {
    const newTimes = [...times];
    newTimes[index].selected = !newTimes[index].selected;
    setTimes(newTimes);

    const selectedTimeIndex = selectedTimes.indexOf(times[index].time);

    if (selectedTimeIndex > -1) {
      setSelectedTimes(selectedTimes.filter((time, index) => index !== selectedTimeIndex));
    }
    else {
      setSelectedTimes([...selectedTimes, times[index].time]);
    }
  }

  function handleSave() {
    if (selectedTimes.length === 0) {
      alert('Por favor, selecione algum horário.');
    }
    else {
      const schedule = { date, times: selectedTimes };
      dispatch(addSchedule(schedule));
      setTimes([...INITIAL_TIMES]);
      setSelectedTimes([]);
      setVisible(false);
    }
  }

  function renderInterval(start, end) {
    const toRender = [];

    for (let i = start; i < end; i++) {
      const time = times[i];

      toRender.push((
        <FormControlLabel
          key={i}
          onChange={() => handleChangeTime(i)}
          control={<Checkbox color="primary" checked={time.selected} name={time.time} />}
          label={time.time}
        />
      ));
    }

    return toRender;
  }

  return (
    <Box className={`${classes.absolute} ${show ? classes.show : classes.hidden}`}>
      <Box variant="section" className={classes.boxWindow}>
        <Box variant="div" className={classes.title}>
          <Typography variant="h4">Adicionar horário disponível</Typography>
          <Box className={classes.closeButton} onClick={() => setVisible(false)}>
            <Close />
          </Box>
        </Box>
        <Box variant="div">
          <Box variant="div" className={classes.dateBox}>
            <TextField
              id="date"
              label="Data"
              type="date"
              className={classes.dateField}
              value={date}
              onChange={e => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography variant="h3" className={classes.dateText}>
              {getStringDate(date)}
            </Typography>
          </Box>
        </Box>
        <Box variant="div" className={classes.timesBox}>
          <Box><b>Horários</b></Box>
          <Box variant="section" className={classes.times}>

            <Box variant="div" className={classes.colTime}>
              <Typography variant="h4">Madrugada</Typography>

              {renderInterval(0, 5)}
            </Box>

            <Box variant="div" className={classes.colTime}>
              <Typography variant="h4">Manhã</Typography>

              {renderInterval(5, 12)}
            </Box>

            <Box variant="div" className={classes.colTime}>
              <Typography variant="h4">Tarde</Typography>

              {renderInterval(12, 19)}
            </Box>

            <Box variant="div" className={classes.colTime}>
              <Typography variant="h4">Noite</Typography>

              {renderInterval(19, 24)}
            </Box>

          </Box>
        </Box>

        <Box variant="div" className={classes.bottomButtons}>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'initial' }}
            disableElevation
            endIcon={<Done />}
            onClick={() => handleSave()}
          >
            Pronto
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default FormSchedule;