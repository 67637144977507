import React, { useState, useEffect } from 'react';
import { Box, LinearProgress, Fade, Button, Typography, Container } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory, Switch, Route } from 'react-router-dom';
import useStyle from './style';
import DestinyPoint from '../DestinyPoint';
import Categories from '../Categories';
import Schedules from '../Schedules';
import PointsAvaliable from '../PointsAvaliable';
import Itinerary from '../Itinerary';

const horizontalLogoCarpediem = require('../../assets/carpediemHorizontalLogo.svg');
const iconLogoCarpediem = require('../../assets/carpediemIconLogo.svg');

const Form = () => {
  const classes = useStyle();
  const history = useHistory();
  const [title, setTitle] = useState();
  const [page, setPage] = useState(2);
  const [progress, setProgress] = useState(0);
  const routes = [
    '/',
    '/form/destino',
    '/form/categorias',
    '/form/horarios',
    '/form/pontosDisponiveis',
    '/form/itinerario',
  ]

  useEffect(() => {
    setProgress(100 * page / routes.length);
    history.push(routes[page - 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function nextPage() {
    if (page < routes.length) {
      setPage(page + 1);
    }
  }

  function backPage() {
    if (page >= 0) {
      setPage(page - 1);
    }
  }

  function setTitles(newTitle = "") {
    setTitle(newTitle);
    document.title = ` Meu Itinerário | ${newTitle}`;
  }

  return (
    <Fade in={true}>
      <Box>
        <Box component="nav" className={classes.nav}>
          <Container maxWidth="md" className={classes.navContent}>
            <Box variant="div" className={classes.colNav}>
              <Button color="primary" onClick={backPage}>
                <ArrowBack />
              </Button>
            </Box>

            <Box variant="div" className={classes.colNav} style={{ justifyContent: 'center' }}>
              <Typography variant="h2">
                <Box component="span" color="primary">{page} </Box>
              de {routes.length}
              </Typography>
            </Box>

            <Box variant="div" className={classes.colNav} style={{ justifyContent: 'flex-end' }}>
              <img
                src={horizontalLogoCarpediem}
                alt="Logo horizontal CarpeDiem."
                className={classes.horizontalLogoCarpediem}
              />
              <img
                src={iconLogoCarpediem}
                alt="ícone CarpeDiem."
                className={classes.iconLogoCarpediem}
              />
            </Box>
          </Container>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box component="section" className={classes.main}>
          <Container maxWidth="md">
            <Box component="div" className={classes.titleBox}>
              <Typography variant="h2">
                {title}
              </Typography>
            </Box>
            <Switch>
              <Route path="/form/destino" exact> <DestinyPoint cbSetTitle={setTitles} cbNextPage={nextPage} /> </Route>
              <Route path="/form/categorias" exact> <Categories cbSetTitle={setTitles} cbNextPage={nextPage} /> </Route>
              <Route path="/form/horarios" exact> <Schedules cbSetTitle={setTitles} cbNextPage={nextPage} /> </Route>
              <Route path="/form/pontosDisponiveis" exact> <PointsAvaliable cbSetTitle={setTitles} cbNextPage={nextPage} /> </Route>
              <Route path="/form/itinerario" exact> <Itinerary cbSetTitle={setTitles} cbNextPage={nextPage} /> </Route>
            </Switch>
          </Container>
        </Box>
      </Box>
    </Fade>
  );
}

export default Form;