export function changeDestiny({ uf, local }) {
  const destiny = {uf, local};

  return {
    type: 'CHANGE_DESTINY',
    destiny
  }
}

export function addSchedule(schedule) {
  return {
    type: 'ADD_SCHEDULE',
    schedule
  }
}

export function removeSchedule(scheduleIndex) {
  return {
    type: 'REMOVE_SCHEDULE',
    scheduleIndex
  }
}

export function addCategoty(category) {
  return {
    type: 'ADD_CATEGORY',
    category
  }
}

export function removeCategory(category) {
  return {
    type: 'REMOVE_CATEGORY',
    category
  }
}