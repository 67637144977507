const INITIAL_STATE = {
  GET_POINTS_TO_SELECT_NOW: false,
}

const reducer = (store = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_POINTS_TO_SELECT_NOW': 
      return {...store, getPointsToSelectNow: action.status};
    default:
      return store;
  }
}

export default reducer;