import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => createStyles({
  all: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    }
  },

  containerBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: '93vh',
    }
  },

  content: {
    display: 'flex',
    flexDirection: 'row',
    width: 1000,
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '80%',
    }
  },

  imagesBox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },

  formBox: {
    width: '45%',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    }
  },

  mobileLogo: {
    maxHeight: 230,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },

  largerLogo: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      "& label": {
        marginTop: 10,
        marginBottom: 10
      }
    }
  },

  inputGroup: {
    height: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    }
  },
  
  informations: {
    "& h1": {
      marginBottom: 10,
    },
    "& h3": {
      marginBottom: 20,
    },

    [theme.breakpoints.down('sm')]: {
      "& h1": {
        marginBottom: 10,
      },

      "& h3": {
        marginBottom: 20,
        fontSize: 18,
      },
    }
  },

  buttons: {
    "& > *": {
      marginTop: 10,
    }
  },

  button: {
    height: 50,
    fontSize: 15,
  },

  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '80%',
      marginTop: 30,
    }
  },
  
  footerInfo: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    margin: 10,
    "& h4": {
      marginLeft: 5,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 10,
    }
  }
}));
