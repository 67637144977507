import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Typography, Box, TextField, InputAdornment, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { ArrowForward, ArrowBack, Mail, AccountBox, Place, Phone } from '@material-ui/icons';
import { useStyles } from './style';
import { useHistory } from 'react-router-dom';
import TopInfinityProgress from '../../components/TopInfinityProgress';

const mobileLogo = require('../../assets/carpediemVerticalLogo.svg');
const largerLogo = require('../../assets/carpediemWithMap.svg');

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [receiveEmails, setReceiveEmails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = 'Meu Itinerário - Início | Carpediem Homes';
    return () => {
    }
  })

  async function handleSubmit(event) {
    event.preventDefault();

    if (name !== '' && email !== '') {
      setIsLoading(true);

      await api.post('/user', { email, name, receiveEmails }, { headers: { 'Content-Type': 'application/json' } });

      setIsLoading(false);
      history.push('/form');
    }

    if (name === '') {
      setNameError(true);
    }

    if (email === '') {
      setEmailError(true);
    }
  }

  return (
    <Box component="div" className={classes.all}>
      <TopInfinityProgress hidden={!isLoading} />
      <Box className={classes.containerBox}>
        <Box className={classes.content} component="main">
          <Box className={classes.imagesBox} component="div">
            <img className={classes.mobileLogo} src={mobileLogo} alt="Logo horizontal do Carpediem Homes." />
            <img className={classes.largerLogo} src={largerLogo} alt="Logo do Meu Itinerário com o logo do CarpeDiem Homes." />
          </Box>
          <Box className={classes.formBox} component="div">
            <Box component="section" className={classes.informations}>
              <Typography variant="h1">
                Meu Itinerário
              </Typography>
              <Typography variant="h3">
                Monte seu roteiro de viagem que mais se encaixa com você.
              </Typography>
            </Box>

            <form onSubmit={handleSubmit} className={classes.form}>
              <Box component="div" className={classes.inputGroup}>
                <TextField
                  placeholder="Email"
                  type="email"
                  value={email}
                  name="email"
                  onChange={textFieldProps => setEmail(textFieldProps.target.value)}
                  variant="outlined"
                  fullWidth
                  error={emailError}
                  label={emailError && 'Por favor, informe seu email'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <Mail style={{ color: '#c4c4c4' }} />
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  placeholder="Nome completo"
                  type="text"
                  value={name}
                  name="name"
                  onChange={textFieldProps => setName(textFieldProps.target.value)}
                  variant="outlined"
                  fullWidth
                  error={nameError}
                  label={nameError && 'Por favor, informe seu nome completo'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <AccountBox style={{ color: '#c4c4c4' }} />
                      </InputAdornment>
                    )
                  }}
                />

                <FormControlLabel
                  checked={receiveEmails}
                  onChange={() => setReceiveEmails(!receiveEmails)}
                  control={<Checkbox color="primary" />}
                  label="Desejo receber emails da Carpediem Homes"
                  labelPlacement="start"
                />
              </Box>

              <Box className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<ArrowForward style={{ fontSize: 24 }} />}
                  style={{ textTransform: 'initial' }}
                  type="submit"
                  fullWidth
                  disableElevation
                  onClick={e => handleSubmit(e)}
                > Começar agora </Button>

                <Button
                  href="https://carpediemhomes.com.br/"
                  color="primary"
                  className={classes.button}
                  startIcon={<ArrowBack style={{ fontSize: 24 }} />}
                  style={{ textTransform: 'initial' }}
                  fullWidth
                  disableElevation
                > Voltar para a página anterior </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>

      <Box component="footer" className={classes.footer}>
        <Box component="span" className={classes.footerInfo}>
          <Place color="primary" style={{ fontSize: 15 }} />
          <Typography variant="h4">
            <b>Campos Sales, 901. Manhattan Business. Sala 1707</b>
          </Typography>
        </Box>
        <Box component="span" className={classes.footerInfo}>
          <Phone color="primary" style={{ fontSize: 15 }} />
          <Typography variant="h4">
            <b>Reservas: 84 99191-9951</b>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Home;
