import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Form from './pages/Form';

const Routes = () => {
  return (
    <BrowserRouter>
        <Switch>
          <Route path='/' exact> <Home/> </Route>
          <Route path='/form'> <Form/> </Route>
        </Switch>
    </BrowserRouter>
  );
}

export default Routes;