import React from 'react';
import Carousel from 'react-material-ui-carousel'
import useStyle from './style';
import { baseURL } from '../../services/api';

const PersonalCarousel = ({ imageNames = [] }) => {
  const classes = useStyle();

  return (
    <Carousel
      navButtonsAlwaysVisible={true}
      autoPlay={true}
      startAt={0}
      activeIndicatorProps={false}
      animation="slide"
      className={classes.carousel}
      interval={3000}
    >
      {
        imageNames.map((imageName, i) => (
          <img key={i} className={classes.image} src={`${baseURL}/public/images/${imageName}`} alt={imageName} />
        ))
      }
    </Carousel>
  )
}

export default PersonalCarousel;