import React, { useEffect, useState } from 'react';
import { Grow, Container, Box, Typography, Card, Button } from '@material-ui/core';
import { getStringDate } from '../../utils/DateUtils';
import { useSelector, useDispatch } from 'react-redux';
import { AccessTime } from '@material-ui/icons';
import api, { baseURL } from '../../services/api';
import useStyle from './style';
import PointDetail from '../PointDetail';
import { addDataToGetItinerary } from '../../store/dataToGetItinerary/actions';
import ButtonNext from '../../components/ButtonNext';

const PointsAvaliable = ({ cbSetTitle, cbNextPage }) => {
  const classes = useStyle();
  const status = useSelector(store => store.status);
  const dispatch = useDispatch();
  const [pointToShow, setPointToShow] = useState({});
  const [showPointDetail, setShowPointDetail] = useState(false);
  const [pointsResult, setPointsResult] = useState([]);
  const [itinerary, setItinerary] = useState([]);
  const formData = useSelector(store => store.form);

  useEffect(() => {
    cbSetTitle('Selecione os seus pontos de interesse');

    if (status.getPointsToSelectNow) {
      async function submit() {
        const requestData = {
          city: formData.destiny.local,
          uf: formData.destiny.uf,
          categories: formData.categories,
          schedules: formData.schedules
        }

        const response = await api.post('/itinerary', requestData);
        setPointsResult(response.data);
      }

      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newItinerary = pointsResult.map(pointResult => {
      const itineraryItem = {
        date: pointResult.date,
        pointIdsAndTimes: []
      }

      for (const pointByTime of pointResult.pointsByTime) {
        itineraryItem.pointIdsAndTimes.push({
          time: pointByTime.time,
          pointId: null
        });
      };

      return itineraryItem;
    });

    setItinerary(newItinerary);
  }, [pointsResult]);

  function showPoint(point) {
    setShowPointDetail(true);
    setPointToShow(point);
  }

  function handleSelectPoint(pointByTime, date, time) {
    const newItinerary = itinerary.map(itineraryItem => {
      if (itineraryItem.date === date) {
        let pointIdAndTimes = itineraryItem
          .pointIdsAndTimes
          .filter((pointIdAndTimes) => pointIdAndTimes.time === time);

        if (pointIdAndTimes.length !== 0) {
          pointIdAndTimes = pointIdAndTimes[0];
          pointIdAndTimes.pointId = pointByTime.id;
        }
      }

      return itineraryItem;
    });

    setItinerary(newItinerary);
    dispatch(addDataToGetItinerary(newItinerary));
  }

  function isPointSelected(point, date, time) {
    let result = false;
    itinerary.map(itineraryItem => {
      if (itineraryItem.date === date) {
        let pointIdAndTimes = itineraryItem
          .pointIdsAndTimes
          .filter((pointIdAndTimes) => pointIdAndTimes.pointId === point.id && pointIdAndTimes.time === time);

        if (pointIdAndTimes.length !== 0) {
          result = true;
        }
      }
      return result;
    });

    return result;
  }

  function renderPoints(pointByTime, date) {
    if (pointByTime.points.length === 0) {
      return (
        <Typography className={classes.errorMessage}>
          Nada foi encontrado para você nesse horário.
        </Typography>
      );
    }
    else {
      return pointByTime.points.map((point, pointIndex) => (
        <Card className={`${classes.card} ${isPointSelected(point, date, pointByTime.time) ? classes.cardSelected : {}}`} key={pointIndex}>
          <img src={`${baseURL}/public/images/${point.images[0].name}`} alt={point.images[0].name} className={classes.cardImage} />
          <Box className={classes.cardContent}>
            <Box className={classes.cardTitle}>
              <Typography className={classes.cardName}>{point.name}</Typography>
              <Box className={classes.cardTimes}>
                <Typography>{point.workingTimes[0].openingTime.substring(0, 5)}</Typography>
                <Typography>{point.workingTimes[0].closingTime.substring(0, 5)}</Typography>
              </Box>
            </Box>
            <Box className={classes.cardCategories}>
              {point.categories.map((category, categoryIndex) => (
                <Typography key={categoryIndex} className={classes.cardCategory}>
                  {category.category}
                </Typography>
              ))}
            </Box>
            <Box className={classes.cardButton}>
              <Button 
                href={point.details} 
                style={{ textTransform: 'initial' }}
                disableElevation 
                onClick={() => showPoint(point)}
              >
                <b>Detalhes</b>
              </Button>
              <Button
                color="primary"
                style={{ textTransform: 'initial' }}
                disableElevation 
                onClick={() => handleSelectPoint(point, date, pointByTime.time)}
              >
                <b>Selecionar</b>
              </Button>
            </Box>
          </Box>
        </Card>
      ))
    }
  }

  return (
    <Grow in={true}>
      <Box variant="div">
        <PointDetail show={showPointDetail} setVisible={setShowPointDetail} point={pointToShow}/>
        <ButtonNext onClick={cbNextPage}/>
        <Typography className={classes.message}>É possível escolher somente uma opção por horário.</Typography>
        <Container className={classes.container}>
          {pointsResult.length !== 0 && pointsResult.map((pointResult, scheduleIndex) => (

            <Box variant="div" className={classes.boxDay} key={scheduleIndex}>
              <h1 className={classes.boxDayTitle}>{getStringDate(pointResult.date)}</h1>

              {pointResult.pointsByTime.map((pointByTime, indexPointByTime) => (

                <Box variant="div" key={indexPointByTime}>
                  <Box variant="div" className={classes.titleTime}>
                    <AccessTime />
                    {pointByTime.time}
                  </Box>

                  <Box variant="div" className={classes.cards}>
                    {renderPoints(pointByTime, pointResult.date)}
                  </Box>
                </Box>
              ))}
            </Box>
          ))}

        </Container>
      </Box>
    </Grow>
  );
}

export default PointsAvaliable;
