import { makeStyles, createStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => createStyles({
  floatButton: {
    position: 'fixed',
    zIndex: 10000,
    bottom: 20,
    right: 20,
    borderRadius: '100%',
    height: 70,
    width: 70,
  }
}));

export default useStyle;