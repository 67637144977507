const { makeStyles } = require("@material-ui/core");

const useStyle = makeStyles({
  topInfinityProgress: {
    position: 'fixed',
    width: '100%',
    top: 0,
  }
});

export default useStyle;