import React from 'react';
import useStyle from './style';
import { LinearProgress } from '@material-ui/core';

const TopInfinityProgress = ({ hidden }) => {
  const classes = useStyle();

  return <LinearProgress hidden={hidden} className={classes.topInfinityProgress}/>
};

export default TopInfinityProgress;