/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Close, Place } from '@material-ui/icons';
import useStyle from './style';
import Carousel from '../../components/Carousel';

const PointDetail = ({ point, show, setVisible }) => {
  const classes = useStyle();
  const [imageNames, setImageNames] = useState([]);

  useEffect(() => {
    setImageNames(getPointImageNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [point]);

  function getPointImageNames() {
    if (point.images) {
      return point.images.map(image => image.name);
    }
    return [];
  }

  return (
    <Box className={`${classes.absolute} ${show ? classes.show : classes.hidden}`}>
      <Box variant="section" className={classes.boxWindow}>
        <Box variant="div" className={classes.title}>
          <Typography variant="h4">{point.name} - {point.phone}</Typography>
          <Box className={classes.closeButton} onClick={() => setVisible(false)}>
            <Close />
          </Box>
        </Box>
        <Box>
          <Carousel imageNames={imageNames} />
        </Box>
        <Box className={classes.categories}>
          {point.categories && point.categories.map((category, i) => (
            <Box className={classes.category} key={i}>
              {category.category}
            </Box>
          ))}
        </Box>
        <Box>
          <Typography variant="h3" className={classes.description}>
            {point.description}
          </Typography>
        </Box>
        <Box className={classes.locals}>
          <Place />
          {point.addresses && point.addresses.map((address, i) => (
            <Box className={classes.local} key={i}>
              <Typography>{address.description}</Typography>

              <a
                className={classes.link}
                target="_blank"
                href={`https://www.google.com.br/maps/@${address.latitude},${address.longitude},19z`}
              >
                Abrir no Google Maps
              </a>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default PointDetail;