import { makeStyles, createStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => createStyles({
  nav: {
    width: '100%',
    position: 'fixed',
    backgroundColor: theme.palette.background.default,
    zIndex: 9999,
  },

  navContent: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  colNav: {
    width: '33%',
    display: 'flex',
  },

  horizontalLogoCarpediem: {
    height: 50,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },

  iconLogoCarpediem: {
    height: 50,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },

  main: {
    paddingTop: 70,
    paddingBottom: 70,
    // minHeight: '91vh',
  },

  titleBox: {
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },

  floatButton: {
    position: 'fixed',
    bottom: 20,
    right: 20,
  }
}));

export default useStyle;