import React, { useState, useEffect } from 'react';
import useStyle from './style';
import api from '../../services/api';
import ButtonNext from '../../components/ButtonNext';
import { Grow, Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { changeDestiny } from '../../store/form/actions';

const DestinyPoint = ({ cbSetTitle, cbNextPage }) => {
  const classes = useStyle();
  const destiny = useSelector(state => state.form.destiny);
  const dispatch = useDispatch();
  const [selectedLocal, setSelectedLocal] = useState(-1);
  const [selectedUf, setSelectedUf] = useState(-1);
  const [disponiblesLocals, setDisponibleLocals] = useState([]);

  // On component mount
  useEffect(() => {
    cbSetTitle('Selecione seu destino');

    async function loadLocals() {
      const response = await api.get('/destinations');

      if (response.status === 200) {
        setDisponibleLocals(response.data);
      }
    }

    loadLocals().then(() => {
      disponiblesLocals.map((value, index) => {
        if (destiny.uf === value) {
          setSelectedUf(index);
        }
        return value;
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On select destiny
  useEffect(() => {
    if (selectedLocal !== -1 && selectedUf !== -1) {
      const uf = disponiblesLocals[selectedUf].uf;
      const local = disponiblesLocals[selectedUf].locals[selectedLocal];

      dispatch(changeDestiny({ uf, local }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocal, selectedUf]);

  function handleSelectUf(event) {
    const value = event.target.value;

    if (value !== -1) {
      setSelectedUf(value);
      setSelectedLocal(-1);
    }
  }

  function handleSelectLocal(event) {
    const value = event.target.value;

    if (value !== -1) {
      setSelectedLocal(value);
    }
  }

  function goToNextPage() {
    if(selectedLocal !== -1 && selectedUf !== -1) {
      cbNextPage();
    }
    else {
      alert('Por favor, selecione o destino.');
    }
  }

  return (
    <Box>
      <ButtonNext onClick={goToNextPage} />
      <Grow in={true}>
        <Box component="div">
          <Box component="section" className={classes.content}>
            <FormControl variant="outlined" className={classes.selectField}>
              <InputLabel id="select-state-label">Estado</InputLabel>
              <Select
                labelId="select-state-label"
                id="select-state-outlined"
                value={selectedUf}
                onChange={handleSelectUf}
                label="Estado"
                disabled={disponiblesLocals.length === 0? true: false}
              >
                <MenuItem value={-1}>
                  <em>Por favor, selecione o estado.</em>
                </MenuItem>

                {disponiblesLocals.map((disponible, index) => {
                  return (<MenuItem value={index} key={index}>{disponible.uf}</MenuItem>)
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.selectField}>
              <InputLabel id="select-local-label">Local</InputLabel>
              <Select
                labelId="select-local-label"
                id="select-local-outlined"
                value={selectedLocal}
                onChange={handleSelectLocal}
                label="Local"
                disabled={disponiblesLocals.length === 0? true: false}
              >
                <MenuItem value={-1}>
                  <em>Por favor, selecione o local</em>
                </MenuItem>

                {selectedUf !== -1 && disponiblesLocals[selectedUf].locals.map((local, index) => {
                  return (<MenuItem value={index} key={index}>{local}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Grow>
    </Box>
  )
}

export default DestinyPoint;
