export const days = [
  'domingo',
  'segunda-feira',
  'terça-feira',
  'quarta-feira',
  'quinta-feira',
  'sexta-feira',
  'sábado'
];

export const months = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro'
]

export function getCurrentDate() {
  const date = new Date();
  const day = date.getDate().toString().length > 1 ? date.getDate() : `0${date.getDate()}`;
  const month = (date.getMonth() + 1).toString().length > 1 ? date.getMonth() : `0${date.getMonth() + 1}`;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function serializeDate(selectedDate) {
  const date = selectedDate;

  const year = Number(date.substring(0, 4));
  const month = Number(date.substring(5, 7));
  const day = Number(date.substring(8, 11));

  return { year, month, day };
}

export function getStringDate(selectedDate) {
  const serializatedDate = serializeDate(selectedDate);
  const date = new Date(
    serializatedDate.year,
    serializatedDate.month - 1,
    serializatedDate.day
  );

  let stringDate = `${days[date.getDay()]}, ${serializatedDate.day} de ${months[date.getMonth()]} de ${date.getFullYear()}.`;
  stringDate = stringDate.replace(stringDate.charAt(0), stringDate.charAt(0).toUpperCase());
  return stringDate;
}

export function getWeekDay(date) {
  const serializatedDate = serializeDate(date);
  const newDate = new Date(serializatedDate.year, serializatedDate.month, serializatedDate.day);
  const day = newDate.getDay();
  return days[day];
}