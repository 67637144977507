import React, { useEffect, useState } from 'react';
import { Grow, Box, Typography } from '@material-ui/core';
import useStyle from './style';
import api, { baseURL } from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { addCategoty, removeCategory } from '../../store/form/actions';
import ButtonNext from '../../components/ButtonNext';

const Categories = ({ cbSetTitle, cbNextPage }) => {
  const classes = useStyle();
  const selectedCategories = useSelector(store => store.form.categories);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  //const [totalCategories, setTotalCategories] = useState(0);

  useEffect(() => {
    cbSetTitle('Quais suas categorias de interesse?');

    // api.get('/categories/total').then(response => {
    //   return setTotalCategories(response.data);
    // })

    api.get('/categories').then(response => {
      return setCategories(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectCategory(id) {
    if (!selectedCategories.includes(id)) {
      dispatch(addCategoty(id));
    }
    else {
      dispatch(removeCategory(id));
    }
  }

  function goToNextPage() {
    if (selectedCategories.length !== 0) {
      cbNextPage();
    }
    else {
      alert('Por favor, selecione pelo menos uma categoria.');
    }
  }

  return (
    <Box>
      <ButtonNext onClick={goToNextPage} />
      <Grow in={categories.length !== 0}>
        <Box component="div">
          <Box component="section" className={classes.content}>
            {categories.map(category => (
              <Box
                className={`${classes.card} ${selectedCategories.includes(category.id) ? classes.cardSelected : {}}`}
                key={category.id}
                onClick={() => handleSelectCategory(category.id)}
              >
                <img className={classes.cardImage} src={`${baseURL}/public/categoriesIcons/${category.image}`} alt="" />
                <Typography variant="h3" className={classes.cardTitle}>{category.category}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Grow>
    </Box>
  );
};

export default Categories;